import { useCallback } from 'react'
import fnGetNotificationData from '@functions/fnGetNotificationData'

export default function useApi({ setUser, setLayout, setNotification }) {
    const fnFetch = useCallback(
        async (method, route, values = {}, config = {}) => {
            const options = {
                method: method,
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                ...(method !== 'GET' && { body: JSON.stringify(values) }),
            }

            try {
                const response = await fetch(global.TRIBU_API_URL + route, options)
                const json = await response.json()

                if (response.ok) {
                    if (config.skipSuccessMessage || method === 'GET') {
                        return Promise.resolve(json)
                    } else {
                        setNotification(fnGetNotificationData(options.method, values, 'SUCCESS'))
                        return Promise.resolve(json)
                    }
                }

                if (['UNAUTHORIZED_SESSION', 'AUTHENTICATION_FAILED', 'NO_IDENTITY_FOUND'].includes(json)) {
                    setUser(null)
                }

                if (json === 'INVALID_SUBSCRIPTION' || json === 'SITE_NOT_FOUND') {
                    setLayout(json)
                }

                if (json.error) {
                    setNotification(fnGetNotificationData('API_ERROR', json, 'ERROR'))
                } else {
                    setNotification(fnGetNotificationData(json, values, 'ERROR'))
                }

                return Promise.reject(json)
            } catch (error) {
                setNotification(fnGetNotificationData('SERVER_ERROR', values, 'ERROR'))
                return Promise.reject(error)
            }
        },
        [setUser, setLayout, setNotification]
    )

    const apiGet = useCallback(
        (route, config = {}) => {
            return fnFetch('GET', route, {}, config)
        },
        [fnFetch]
    )

    const apiPost = useCallback(
        (route, values, config = {}) => {
            return fnFetch('POST', route, values, config)
        },
        [fnFetch]
    )
    const apiPut = useCallback(
        (route, values, config = {}) => {
            return fnFetch('PUT', route, values, config)
        },
        [fnFetch]
    )

    const apiDelete = useCallback(
        (route, config = {}) => {
            return fnFetch('DELETE', route, {}, config)
        },
        [fnFetch]
    )

    return { apiGet, apiPost, apiDelete, apiPut }
}
