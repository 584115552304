import React from 'react'
import IsOnline from '@components/isOnline'
import { IconYogaLotus } from '@shared/icons'

export default function ErrorScreen({ title, text }) {
    return (
        <main className='relative isolate grid min-h-screen w-screen place-items-center px-4'>
            <div className='fixed top-0 w-full'>
                <IsOnline />
            </div>
            <div className='absolute inset-0 -z-10 h-full w-full bg-slate-200' />
            <div className='mx-auto max-w-2xl rounded bg-white px-6 py-8 text-center md:px-12'>
                <IconYogaLotus className='mx-auto size-16 rounded-full bg-haze/10 stroke-haze p-2' />
                <h1 className='mt-4 font-lmp-regular text-2xl'>{title}</h1>
                <p className='mt-2 text-center text-sm text-[#777777]'>{text}</p>
            </div>
        </main>
    )
}
