import React, { useEffect, useState } from 'react'

// utils
import WebContext from '@shared/context-web'
import ApiContext from '@shared/context-api'
import UserContext from '@shared/context-user'

// components
import Spinner from '@components/spinner'
import useApi from '@components/layout/useApi'
import Notification from '@components/layout/notification'
import ErrorScreen from '@components/layout/errorScreen'

export default function Layout({ children, location }) {
    const [web, setWeb] = useState({})
    const [user, setUser] = useState(null)
    const [layout, setLayout] = useState('LOADING')
    const [notification, setNotification] = useState(null)
    const { apiGet, apiPost, apiDelete, apiPut } = useApi({ setUser, setLayout, setNotification })

    useEffect(() => {
        apiGet('/website/data')
            .then((response) => {
                setWeb(response)
                setLayout(null)
            })
            .catch((e) => {
                if (e !== 'INVALID_SUBSCRIPTION' && e !== 'SITE_NOT_FOUND') {
                    setLayout('SERVER_ERROR')
                }
            })
    }, [apiGet])

    useEffect(() => {
        apiGet('/identity')
            .then((user) => {
                setUser(user)
            })
            .catch(() => {})
    }, [apiGet])

    if (layout === 'LOADING') {
        return <Spinner fullscreen={true} />
    }

    if (layout === 'INVALID_SUBSCRIPTION') {
        return (
            <ErrorScreen
                title='Estudio Inactivo'
                text='El shala virtual está temporalmente suspendido; ponte en contacto con tu estudio para coordinar tu acceso.'
            />
        )
    }

    if (layout === 'SITE_NOT_FOUND') {
        return (
            <ErrorScreen
                title='404: Error'
                text='El sitio que buscas ya no existe. Comprueba la dirección, reinstala la aplicación o ponte en contacto con tu estudio para coordinar tu acceso.'
            />
        )
    }

    if (layout === 'SERVER_ERROR') {
        return (
            <ErrorScreen
                title='Error de Servidor'
                text='Ocurrió un error en nuestros servidores; vuelve a intentarlo en unos minutos o verifica tu conexión a Internet.'
            />
        )
    }

    return (
        <ApiContext.Provider value={{ apiGet, apiPost, apiDelete, apiPut, setNotification }}>
            <WebContext.Provider value={{ location, ...web }}>
                <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
            </WebContext.Provider>
            <Notification notification={notification} setNotification={setNotification} />
        </ApiContext.Provider>
    )
}
