import React from 'react'

const Balls = ({ bgColor = '', ballColor = 'bg-emperor' }) => (
    <div className={`${bgColor} mx-auto flex items-center justify-items-center gap-3 text-center`}>
        <div
            className={`${ballColor} h-3 w-3 animate-[tribu-spinner-ball_1.4s_infinite_ease-in-out_both] rounded-full delay-75 ease-in-out`}
            style={{ animationDelay: '-0.32s' }}
        />
        <div
            className={`${ballColor} h-3 w-3 animate-[tribu-spinner-ball_1.4s_infinite_ease-in-out_both] rounded-full delay-100 ease-in-out`}
            style={{ animationDelay: '-0.16s' }}
        />
        <div
            className={`${ballColor} h-3 w-3 animate-[tribu-spinner-ball_1.4s_infinite_ease-in-out_both] rounded-full delay-150 ease-in-out`}
            style={{ animationDelay: '0s' }}
        />
    </div>
)

export default function Spinner({ fullscreen, bgColor, ballColor }) {
    return fullscreen ? (
        <div className='fixed inset-0 z-[999999999] bg-white'>
            <div className='flex min-h-full justify-center p-4 sm:items-center sm:p-0'>
                <Balls bgColor={bgColor} ballColor={ballColor} />
            </div>
        </div>
    ) : (
        <div className='mx-auto flex justify-center'>
            <Balls bgColor={bgColor} ballColor={ballColor} />
        </div>
    )
}
